import React from 'react';
import { Link } from 'gatsby';
import Header from './header.component.es';
import FooterComponentES from './footer.component.es';
import CookieConsent from 'react-cookie-consent';
import '../styles/layout.scss';

export default function LayoutES({ children }) {
	return (
		<div className='website'>
			<Header />
			<main>{children}</main>
			<CookieConsent
				location='bottom'
				buttonText='Aceptar'
				declineButtonText='Rechazar'
				cookieName='gdpr-google-tagmanager'
				buttonStyle={{
					borderRadius: '10px',
					background: 'rgb(0, 153, 255)',
					color: 'white',
					padding: '10px',
				}}
			>
				Utilizamos cookies para analizar el tráfico, nada más. Puedes verlo
				escrito (con más parafernalia) en la{' '}
				<Link to='/es/cookies'>Política de privacidad</Link>.
			</CookieConsent>
			<FooterComponentES />
		</div>
	);
}
