import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import '../styles/header.scss';
import logoUrl from '../static/images/logo-kuflow.svg';

export default function Header() {
	const [scroll, setScroll] = useState(false);

	useEffect(() => {
		window.addEventListener('scroll', () => {
			setScroll(window.scrollY > 50);
		});
	}, []);

	return (
		<header className={scroll ? 'shadow header' : 'header'}>
			<div className='header-container'>
				<div className='logo-container'>
					<a href='/'>
						<img src={logoUrl} alt='Logo KuFlow' />
					</a>
				</div>
				<div className='navbar navbar-desktop'>
					<div className='dropdown'>
						<button className='dropbtn'>Soluciones ▼</button>
						<div className='dropdown-content'>
							<Link to='/es/solution-workflow'>Flujos de trabajo</Link>
							<Link to='/es/solution-rpa'>Automatización inteligente</Link>
							<Link to='/es/solution-orchestration'>
								Orquestación de servicios
							</Link>
						</div>
					</div>
					<div className='dropdown'>
						<button className='dropbtn'>Producto ▼</button>
						<div className='dropdown-content'>
							<Link to='/es/product'>Producto</Link>
							<Link to='/es/faq'>En detalle</Link>
						</div>
					</div>
					<div className='dropdown'>
						<button className='dropbtn'>Recursos ▼</button>
						<div className='dropdown-content'>
							<Link to='/es/blog'>Blog</Link>
							<a href='https://docs.kuflow.com/'>Documentación</a>
							<a href='https://community.kuflow.com/'>Foro</a>
							<a href='https://status.kuflow.com/'>Estado de los servicios</a>
						</div>
					</div>
					<Link to='/es/jobs'>Trabaja con nosotros</Link>
					<a href='https://app.kuflow.com/' className='kuflow-signin'>
						Acceder
					</a>
				</div>
				<div className='navbar navbar-mobile'>
					<div className='dropdown'>
						<button className='dropbtn'>Menú ▼</button>
						<div className='dropdown-content'>
							<Link to='/es/solution-workflow'>
								Soluciones &gt; Flujos de trabajo
							</Link>
							<Link to='/es/solution-rpa'>
								Soluciones &gt; Automatización inteligente
							</Link>
							<Link to='/es/solution-orchestration'>
								Soluciones &gt; Orquestación de servicios
							</Link>
							<Link to='/es/product'>Producto &gt; General</Link>
							<Link to='/es/faq'>Producto &gt; En detalle</Link>
							<Link to='/es/blog'>Recursos &gt; Blog</Link>
							<a href='https://docs.kuflow.com/'>Recursos &gt; Documentación</a>
							<a href='https://community.kuflow.com/'>Recursos &gt; Foro</a>
							<a href='https://status.kuflow.com/'>Recursos &gt; Estado de los servicios</a>
							<Link to='/es/jobs'>Trabaja con nosotros</Link>
							<a href='https://app.kuflow.com/' className='kuflow-signin'>
								Acceder
							</a>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
}
