import React from 'react';
import { Link } from 'gatsby';
import '../styles/solutionsblock.scss';

export default function SolutionsBlockES() {
	return (
		<section id='bloque-soluciones'>
			<h2>¿Qué hace KuFlow?</h2>
			<div id='bloque-soluciones_columnas'>
				<Link className='bloque-soluciones_columna' to='/es/solution-workflow'>
					<h3>Flujos de trabajo</h3>
					<p>
						Descubre cómo KuFlow va a ayudar más a tu organización que el café
					</p>
					<p className='bloque-soluciones_link'>Ver más</p>
				</Link>
				<Link className='bloque-soluciones_columna' to='/es/solution-rpa'>
					<h3>Automatización inteligente</h3>
					<p>
						Robots que te ayudan con las tareas más pesadas. Skynet no sabe nada
						(aún)
					</p>
					<p className='bloque-soluciones_link'>Ver más</p>
				</Link>
				<Link
					className='bloque-soluciones_columna'
					to='/es/solution-orchestration'
				>
					<h3>Orquestación de servicios</h3>
					<p>
						Fiable y resistente. Pero cuidado, vas a olvidar dónde se miraban
						los logs
					</p>
					<p className='bloque-soluciones_link'>Ver más</p>
				</Link>
			</div>
		</section>
	);
}
